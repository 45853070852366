<template lang="pug">
q-page.full-height.scroll
  .q-pa-md
    q-btn.q-my-md(label="Get Secrets", color="primary", @click="getSecrets")
    c-dtable-secrets
</template>

<style>
</style>

<script>
export default {
  methods: {
    getSecrets: function () {
      return this.$store.dispatch("getSecrets");
    },
  },
};
</script>
